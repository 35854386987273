
import React from 'react'
import DrMizanurRahman from '../assets/imgs/DrMizanurRahman_MasjidTauheed.png';
import ImamFokrulIslamAlamgir from '../assets/imgs/ImamFokrulIslamAlamgir_MasjidTauheed.jpg';



function ContactUsDetails() {


  return (

    <div className="ExpansionSummaryContainer">
      <h1>CONTACT US FOR DETAILS</h1>
      <div className="ContactUsDetailsWgtBody WidgetsBody">

        <li>
          <div className="listImgCol"><img className="listImg" src={DrMizanurRahman} /></div>
          <div className="ContactUsDetaiInfo">
            <h2>Dr. Mizanur Rahman</h2>
            <p>President</p>
            <p><a href="tel: +1 (754) 214-7774">+1 (754) 214-7774</a></p> 
          </div>
        </li>

        <li>
          <div className="listImgCol"><img className="listImg" src={ImamFokrulIslamAlamgir} /></div>
          <div className="ContactUsDetaiInfo">
            <h2>Imam Fakhrul Islam Alamgir</h2>
            <p>Imam & Khateeb</p>
            <p><a href="tel: +1 (786) 901-4304">+1 (786) 901-4304</a></p> 
          </div>
        </li>



      </div>
    </div>

  );
}










export default ContactUsDetails