
import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PROPIMG from '../assets/imgs/Masjid_Tauheed_Inside.png'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import HandIcon from '../assets/imgs/HandIconGreen.svg'
import ExpansionSummary from './ExpansionSummary'
import ContactUsDetails from './ContactUsDetails'




const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


function TabsSection() {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <>
      <div className="ExpansionTabBar">
        <div className="ExpansionContainer">

          <div className="dontaineButnWhite ShowMobile">
            <div className="ExpansionHeaderDontainerButtonContainer">
              <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/masjid-tauheed">
                <img className="ExpansionFillButtonIcon" src={HandIcon} />Donate now</Button>
            </div>
          </div>

          <Grid container spacing={3}>

            <Grid item md={8} sm={8} xs={12}>
              <Tabs
                value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered >
                <Tab label="Description" href="#Description"/>
                <Tab label="Project Phase" href="#Project-Phase"/>
                <Tab label="Updates" href="#Updates"/>
                {/* <Tab label="Guests" href="#Guests"/>
                <Tab label="Testimonials" href="#Testimonials"/> */}
                <Tab label="Community" href="#Community"/>
              </Tabs>
            </Grid>

            <Grid item md={4} sm={4} xs={12} className="hideMobile">
              <div className="dontaineButnWhite">
                <div className="ExpansionHeaderDontainerButtonContainer">
                  <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/masjid-tauheed">
                    <img className="ExpansionFillButtonIcon" src={HandIcon} />Donate now</Button>
                </div>
              </div>
            </Grid>

          </Grid>

          

        </div>
      </div>

      <div className="ExpansionContainer">

        <div id="Description" className="ExpansionTabsContainer">
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <div className="TabsContainerPannel">
                <h1>From Church to a Masjid and we prayed at the ground floor for 11 years.  Support us for our re-construction to accomodate 500 prayer space in 2 floors</h1>
                <p>The Church was built in 1956 on 1 acre areas by a Germany architecture company in a very unique design. In 2011, Bangladeshi American Muslim, Professor Dr. Mizanur Rahman bought the Church with sincere help of Engineer Aleem Ghani. Professor Dr. Mizanur Rahman initiated and organized the community together and converted it to a beautiful ground-floor Masjid with three projects - Islamic Center of North Miami (ICNM), Masjid Tauheed and Islamic Library (Books and Quran in different languages).</p>
                <div className="TabsContainerQoute">“Whoever builds a mosque for the sake of Allah, then Allah will build for him a house like it in Paradise. (Sahih al-Bukhari, Sahih Muslim)”</div>
                <div className="propertyImg"><img src={PROPIMG} /></div>
                <p>Masjid Tauheed is very active and provides various services to the community including daily 5 prayers with accomodation of 125 prayer spaces, Talimul Quran for children 4 days in a week, Islamic teaching program for everyone, Ta'alimul quran program for elders.  Masjid Tauheed provides free lunch after Jummah every week and Ifter and dinner in the month of Ramadan. For children, they have space and playgound for playing badminton and basketball games. </p>
                <p>Masjid Tauheed is now in need of your help for re-construction of the premise to accomodate over 500 prayer spaces inshaAllah, and more services.  We want to extend Masjid with more activities and engage our community. inshaAllah, we plan to build an Islamic research institute, add more events to inlcude our families and children, facilitate Nikah events and more services inshaAllah.</p>
                {/* <Link className="TextReadMore">READ MORE <KeyboardArrowDownRoundedIcon /></Link>  */}
              </div >
            </Grid>

            <Grid item md={4} xs={12}>
              <ExpansionSummary />
              <ContactUsDetails />
             
            </Grid>


          </Grid>
        </div>
      </div>


    </>


  );
}










export default TabsSection