
import React from 'react'
import Grid from '@material-ui/core/Grid';
import DFLOGO from '../assets/imgs/footer_LogoWhite.png'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';



function DonaitionCategoriesHorizontal() {


  return (

    <div className="DonaitionCategoriesHorizontalContainer">

      <div className="ExpansionContainer HajjGuidContainer">
        <h1 className="DonaitionCategoriesHorizontalTitle">Build a house of Allah, and Allah will build one for you in Jannah</h1>
        {/* <p className="DonaitionCategoriesHorizontalSubTitle">Those who in charity spend of their goods by night and by day, in secret and in public, have their reward with their 
          Lord: on them shall be no fear, nor shall they grieve (Al-Quran 2:274)</p> */}

        <Grid container spacing={2}>

          <Grid item md={4} xs={12}>
            <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://give.deenfund.com/masjid-tauheed">10 Musallah - $10,000</Button>
          </Grid>

          <Grid item md={4} xs={12}>
            <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://give.deenfund.com/masjid-tauheed">8 Musallah - $8000</Button>
          </Grid>

          <Grid item md={4} xs={12}>
            <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://give.deenfund.com/masjid-tauheed">6 Musallah - $6000</Button>
          </Grid>

          <Grid item md={4} xs={12}>
            <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://give.deenfund.com/masjid-tauheed">​4 Musallah - $4000</Button>
          </Grid>

          <Grid item md={4} xs={12}>
            <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://give.deenfund.com/masjid-tauheed">2 Musallah - $2000</Button>
          </Grid>

          <Grid item md={4} xs={12}>
            <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://give.deenfund.com/masjid-tauheed">1 Musallah - $1000</Button>
          </Grid>

          <Grid item md={12} xs={12}>
            <div className="PowerByText">Powered by <a href="https://deenfund.com/"><img className="df_logoPoweredBy" src={DFLOGO} /></a></div>
          </Grid>

        </Grid>
      </div>


    </div>



  );
}










export default DonaitionCategoriesHorizontal