
import React from 'react'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';



function ExpansionSummary() {


  return (

    <div className="ExpansionSummaryContainer">
      <h1>Expansion summary</h1>
      <div className="ExpansionSummaryBody WidgetsBody">
        <li><FiberManualRecordRoundedIcon /> New 3500 Sq Ft space</li>
        <li><FiberManualRecordRoundedIcon /> Accommodate 500+ Brothers and Sisters to pray in congregation</li>
        <li><FiberManualRecordRoundedIcon /> Increase parking space from 15 to 35</li>
        <li><FiberManualRecordRoundedIcon /> Continue and host large events for families and community</li>
        <li><FiberManualRecordRoundedIcon /> Provide Nikah ceremonies with more spaces</li>
        <li><FiberManualRecordRoundedIcon /> Launch new Islamic educational programs for all ages</li>
        <li><FiberManualRecordRoundedIcon /> Promote and motivate our children with physical activities while learning Islam</li>
        <li><FiberManualRecordRoundedIcon /> Provide funeral and graveyard services</li>
      </div>
    </div>

  );
}










export default ExpansionSummary