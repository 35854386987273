
import React from 'react'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Logo from '../assets/imgs/logo.png'
import HandIcon from '../assets/imgs/HandIcon.svg'


function Header() {
  return (
    <div className="ExpansionHeaderContainer">
      <div className="ExpansionContainer ExpansionHeaderInner">
        <Link className="ExpansionHeaderLogo"><img className="ExpansionHeaderLogoImg" src={Logo} /></Link>
        <div className="ExpansionHeaderDontainerButtonContainer">
          <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/masjid-tauheed">
            <img className="ExpansionFillButtonIcon" src={HandIcon} /> Donate now</Button>
        </div>
      </div>
    </div>
  );
}










export default Header